import { template as template_9bb8762d50cd4a8c81a0941e57561c88 } from "@ember/template-compiler";
const EmptyState = template_9bb8762d50cd4a8c81a0941e57561c88(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
