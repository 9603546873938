import { template as template_0f31322af7fa4b3ba4ab192c7e606d77 } from "@ember/template-compiler";
const FKLabel = template_0f31322af7fa4b3ba4ab192c7e606d77(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
