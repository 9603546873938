import { template as template_26af029818e34c9392b642311c8bbd7d } from "@ember/template-compiler";
const WelcomeHeader = template_26af029818e34c9392b642311c8bbd7d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
