import { template as template_ccc8cb4383f04abb9c3a9d23cc023c63 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_ccc8cb4383f04abb9c3a9d23cc023c63(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
