import { template as template_a28e49556b864f958966ff8ea72f1587 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a28e49556b864f958966ff8ea72f1587(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
