import { template as template_aacaa98d3ce5404e86e5f37fb0df4244 } from "@ember/template-compiler";
const FKText = template_aacaa98d3ce5404e86e5f37fb0df4244(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
